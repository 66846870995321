import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LevelVocab extends Component {
    showLevel = () => {
        const findCountVocab = this.props.vocabularyData.filter(value => value.level_id === this.props.levelID)
        return (
            <Link to={this.props.see + this.props.content + "/" + this.props.levelID}
                name=""
                id=""
                className="btn btn-primary"
                role="button"
            >
                <img
                    src="/assets/image/level_vocab.png"
                    className="img-fluid rounded-top"
                    alt=""
                />
                <p>{this.props.content}</p>
                <h5>{findCountVocab.length}言葉</h5>
            </Link>
        )
    }
    render() {
        return (
            <div className="seelevel">
                {this.showLevel()}
            </div>
        );
    }
}
export default LevelVocab;