import React, { Component } from 'react';

class ButtonAlphabet extends Component {
    componentDidMount() {
        document.querySelectorAll('.button_alphabet').forEach((button) => {
            button.addEventListener('click', function () {
                const audio = document.querySelector('.audios');
                const source = document.querySelector('source');
                source.src = this.getAttribute('data-srcAudio');
                audio.load();

                audio.oncanplaythrough = () => {
                    audio.play().catch(error => {
                        console.error('Không thể phát âm thanh:', error);
                    });
                };
            });
        });
    }    
    render() {
        return (
            <button
                className="btn btn-primary button_alphabet"
                data-srcaudio={this.props.sound}
            >
                <audio className="audios">
                    <source src='' type="audio/mpeg" />
                </audio>
                <h2 className="name">{this.props.name}</h2>
                <h3 className="pronunciation">{this.props.pronunciation}</h3>
            </button>
        );
    }
}
export default ButtonAlphabet;