import React, { Component } from 'react';
import { connect } from 'react-redux';
import { lessonAlphabetDone } from '../react-redux/actions/reviewAction';
import { Link } from 'react-router-dom';

class ALessonContentM extends Component {
    componentDidMount() {
        if (this.props.review.lessonAlphabetDoneData === null) {
            this.props.lessonAlphabetDone()
        }
    }

    showlesson = () => {
        if (this.props.review.lessonAlphabetDoneData !== null) {
            const lessonAlphabetDone = this.props.review.lessonAlphabetDoneData.filter(value => value.user_id === this.props.user_id && value.alphabet_type === this.props.nameContent && value.lesson_id === this.props.lesson_id);
            if (lessonAlphabetDone.length > 0) {
                return (
                    <Link to= {this.props.linkTo} name="" id="" className="btn btn-primary" role="button">
                        <h3>{this.props.nameContent}</h3>
                        <img
                            src="/assets/image/completed.png"
                            className="img-fluid rounded-top complete"
                            alt=""
                        />
                    </Link>
                )
            } else {
                return (
                    <Link to= {this.props.linkTo} name="" id="" className="btn btn-primary" role="button">
                        <h3>{this.props.nameContent}</h3>
                        <h4>Tiến hành học</h4>
                    </Link>
                )
            }
        }
    }
    componentDidUpdate() {
        const lessonDone = document.querySelectorAll('img.img-fluid.rounded-top.complete')
        const lastLessonDone = lessonDone[lessonDone.length - 1];
        if (lastLessonDone) {
            lastLessonDone.scrollIntoView({ behavior: 'smooth' });
            // Chờ cho cuộn hoàn tất
            setTimeout(() => {}, 3000);
        }
    }
    render() {
        return (
            <div>
                {this.showlesson()}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        review: state.review
    }
}
const mapDispatchToProps =  {
    lessonAlphabetDone
}
export default connect(mapStateToProps, mapDispatchToProps)(ALessonContentM);