import React, { Component } from "react";

class AudioRecorder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRecording: false,
            audioURL: null
        };
        this.mediaRecorder = null;
        this.audioChunks = [];
    }

    startRecording = async () => {
        // Nếu đang có bản ghi cũ, xóa nó trước khi ghi mới
        if (this.state.audioURL) {
            URL.revokeObjectURL(this.state.audioURL);
            this.setState({ audioURL: null });
        }

        this.setState({ isRecording: true });

        // Bắt đầu ghi âm
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (event) => {
            this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
            const audioBlob = new Blob(this.audioChunks, { type: "audio/mp3" });
            const audioURL = URL.createObjectURL(audioBlob);
            this.setState({ audioURL });
            this.audioChunks = [];
        };

        this.mediaRecorder.start();
    };

    stopRecording = () => {
        this.setState({ isRecording: false });
    
        if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
            this.mediaRecorder.stop();
        }
    
        // Dừng stream audio
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
            this.stream = null;
        }
    };    

    render() {
        const { isRecording, audioURL } = this.state;

        return (
            <div>
                <button onClick={isRecording ? this.stopRecording : this.startRecording} className="recorderAudiobutton">
                    {isRecording ? <i className="fa-solid fa-microphone-slash"></i> : <i className="fa-solid fa-microphone"></i>}
                </button>

                {audioURL && (
                    <div className="audioRecorder">
                        <p>Nội dung đoạn ghi âm:</p>
                        <audio src={audioURL} controls/>
                        <a href={audioURL} download="recorded_audio.mp3" className="audioURLChatBot">
                            <i className="fa-solid fa-download"></i>Lưu ghi âm
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default AudioRecorder;
