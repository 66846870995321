import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Level extends Component {
    showLevel = () => {
        const findCountGrammar = this.props.grammarData.filter(value => value.level_id === this.props.levelID)
        return (
            <Link to={this.props.see + this.props.content + "/" + this.props.levelID}
                name=""
                id=""
                className="btn btn-primary"
                role="button"
            >
                <img
                    src="/assets/image/level_grammar.png"
                    className="img-fluid rounded-top"
                    alt=""
                />
                <p>{this.props.content}</p>
                <h5>{findCountGrammar.length}文法</h5>
            </Link>
        )
    }
    render() {
        return (
            <div className="seelevel">
                {this.showLevel()}
            </div>
        );
    }
}

export default Level;