import { LEVEL } from "../actions/levelAction"

const InitialState = {
    seeLevel:null,
    loadingLevel:true
}
const levelReducer = (state = InitialState, action) => {
    switch (action.type) {
        case LEVEL:
            return {...state,seeLevel: action.levelAction,loadingLevel:false }
        default:
            return state
    }
}
export default levelReducer;