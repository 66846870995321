import React, { Component } from 'react';
import MenuIndex from './MenuIndex';
import InstructionNotice from '../alerts/InstructionNotice';
class Review extends Component {
    render() {
        return (
            <main>
                <MenuIndex />
                <div className="content">
                    <div className='background_second'></div>
                    <div className="reviewAllVocabGrammar noVocabGrammar">
                        <div className="vocabulary">
                            <h3>
                                Hiện tại chưa có <br />từ vựng
                                ôn tập
                            </h3>
                            <button type="button" className="btn btn-primary">
                                Từ vựng
                            </button>
                        </div>
                        <div className="grammar">
                            <h3>
                                Hiện tại chưa có <br />ngữ pháp ôn tập
                            </h3>
                            <button type="button" className="btn btn-primary">
                                Ngữ pháp
                            </button>
                        </div>
                        <InstructionNotice />
                    </div>
                </div>
                <a
                    href="https://m.me/450832184773698"
                    style={{ textDecoration: "none" }}
                    title="Chat với Yukiainn"
                >
                    <div className="messenger">
                        <i className="fa-brands fa-facebook-messenger" />
                    </div>
                </a>
            </main>
        );
    }
}

export default Review;