import React, { Component } from 'react'

class Flashcard extends Component {
    componentDidMount() {
        document.querySelector('.fa-solid.fa-volume-high').addEventListener('click', function () {
            var soundCard = document.querySelector('.audios');
            soundCard.play();
            this.classList.toggle('scale');
            this.addEventListener("webkitAnimationEnd", function () {
                this.classList.remove('scale');
            });
        });
        document.querySelector('.flashCards .flashcardBefore').addEventListener('click',function(){
            var flashCardsAfter = document.querySelector('.flashCards .flashcardAfter');
            this.classList.add('hiddenBefore');
            flashCardsAfter.classList.add('showAfter');
            this.classList.remove('showBefore');
            flashCardsAfter.classList.remove('hiddenAfter');
        })
        document.querySelector('.flashCards .flashcardAfter').addEventListener('click',function(){
            var flashCardsBefore = document.querySelector('.flashCards .flashcardBefore');
            flashCardsBefore.classList.remove('hiddenBefore');
            this.classList.remove('showAfter');
            flashCardsBefore.classList.add('showBefore');
            this.classList.add('hiddenAfter');
        })
    }
    
    render() {
        return (
            <div className="flashCards">
                <div className="sound_flash_cards">
                    <audio className="audios" autoPlay>
                        <source src={this.props.sound} type="audio/mpeg" />
                    </audio>
                    <i className="fa-solid fa-volume-high"/>
                </div>
                <div className="flashcardBefore">
                    <h2 className="name">{this.props.name}</h2>
                    <p className="example">{this.props.example}</p>
                </div>
                <div className="flashcardAfter">
                    <h3 className="mean">{'Ý nghĩa:' + this.props.mean}</h3>
                    <h3 className="pronunciation">{this.props.pronunciation}</h3>
                    <h3 className="sino_vietnamese_sound">{'Âm hán việt: ' + this.props.sino_vietnamese_sound}</h3>
                    <h3 className="example_mean">{this.props.example_mean}</h3>
                </div>
                <i className="fa-solid fa-hand-pointer" />
                <button name="" id="" className="btn btn-primary continue" onClick={this.props.howToLearnNext}> Tiếp tục </button>
            </div>
        );
    }
}

export default Flashcard;