import React, { Component } from 'react';

class AlertErrorStudy extends Component {
    componentDidMount() {
        document.querySelector('i.fa-solid.fa-language').addEventListener('click',function(){
            const example_mean_show = document.querySelector('.example_mean');
            example_mean_show.classList.toggle('show');
        })
        document.querySelector('.fa-solid.fa-volume-high').addEventListener('click', function () {
            var soundCard = document.querySelector('.audios');
            soundCard.play();
            this.classList.toggle('scale');
            this.addEventListener("webkitAnimationEnd", function () {
                this.classList.remove('scale');
            });
        });
    }
    render() {
        return (
            <div className="alerts alertError ">
                <h2>Đáp án sai</h2>
                <div className="alert">
                    <div className="contentAlert">
                        <div className="soundNamePronunciationAll">
                            <div className="sound_listen_alert">
                                <audio className="audios" autoPlay>
                                    <source src={this.props.sound} type="audio/mpeg" />
                                </audio>
                                <i className="fa-solid fa-volume-high"/>
                            </div>
                            <p>{this.props.name + '(' + this.props.pronunciation + ') : ' + this.props.mean}</p>
                        </div>
                        <div className="meanExampleAll">
                            <i className="fa-solid fa-language"></i>
                            <p>{this.props.example}</p>
                        </div>
                        <p className="example_mean">{this.props.example_mean}</p>
                    </div>
                    <button
                        name=""
                        id=""
                        className="btn btn-primary learnAgain"
                        onClick={this.props.howToLearnBack}
                    >
                        {this.props.nameButton}
                    </button>
                </div>
            </div>
        );
    }
}

export default AlertErrorStudy;