import React, { Component } from 'react';

class DrawAlphabet extends Component {
    componentDidUpdate() {
        const canvas = document.getElementById('drawingCanvas');
        const downloadButton = document.getElementById('downloadButton');
        downloadButton.addEventListener('click', () => {
            // Lấy URL của ảnh từ canvas
            const imageURL = canvas.toDataURL('image/jpg');
      
            // Tạo một thẻ <a> tạm thời để tải ảnh
            const link = document.createElement('a');
            link.href = imageURL;
            link.download = 'image.jpg'; // Tên file tải về
            link.click(); // Kích hoạt tải ảnh
        });
        const ctx = canvas.getContext('2d');
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.lineWidth = 8;

        // Tối ưu cho Retina Display
        const devicePixelRatio = window.devicePixelRatio || 1;
        canvas.width = canvas.offsetWidth * devicePixelRatio;
        canvas.height = canvas.offsetHeight * devicePixelRatio;
        ctx.scale(devicePixelRatio, devicePixelRatio);

        let isDrawing = false;
        let currentPath = [];
        let allPaths = [];

        // Lấy vị trí chuột hoặc cảm ứng
        function getPointerPosition(event) {
            const rect = canvas.getBoundingClientRect();
            if (event.touches || event.changedTouches) {
                const touch = event.touches[0] || event.changedTouches[0];
                return {
                    x: touch.clientX - rect.left,
                    y: touch.clientY - rect.top
                };
            } else {
                return {
                    x: event.clientX - rect.left,
                    y: event.clientY - rect.top
                };
            }
        }

        // Vẽ đường nét
        function drawLine(points) {
            if (points.length < 2) return;

            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);

            for (let i = 1; i < points.length; i++) {
                ctx.lineTo(points[i].x, points[i].y);
            }

            ctx.stroke();
        }

        // Sự kiện chuột
        canvas.addEventListener('mousedown', (e) => {
            isDrawing = true;
            currentPath = [getPointerPosition(e)];
        });

        canvas.addEventListener('mousemove', (e) => {
            if (!isDrawing) return;
            currentPath.push(getPointerPosition(e));
            drawLine(currentPath);
        });

        canvas.addEventListener('mouseup', () => {
            if (isDrawing) {
                isDrawing = false;
                allPaths.push([...currentPath]);
            }
        });

        canvas.addEventListener('mouseleave', () => {
            if (isDrawing) {
                isDrawing = false;
                allPaths.push([...currentPath]);
            }
        });

        // Sự kiện cảm ứng
        canvas.addEventListener('touchstart', (e) => {
            e.preventDefault();
            isDrawing = true;
            currentPath = [getPointerPosition(e)];
        });

        canvas.addEventListener('touchmove', (e) => {
            e.preventDefault();
            if (!isDrawing) return;
            currentPath.push(getPointerPosition(e));
            drawLine(currentPath);
        });

        canvas.addEventListener('touchend', () => {
            if (isDrawing) {
                isDrawing = false;
                allPaths.push([...currentPath]);
            }
        });

        // Nút "Xóa"
        document.getElementById('clearButton').addEventListener('click', () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            allPaths = [];
        });
        // kích thước nét 
        const slider = document.getElementById('lineWidthSlider');
        slider.addEventListener('input', (e) => {
            ctx.lineWidth = e.target.value;
        });
    }

    componentDidMount() {
        document.querySelector('.draw-alphabet i.fa-pen-to-square').addEventListener('click', () => {
            const lineWidth = document.querySelector(".lineWidthSliders");
            if (lineWidth) {
                lineWidth.classList.toggle('show');
            }
        });
    }


    render() {
        return (
            <div className="draw-alphabet">
                <h1>Luyện tập</h1>
                <canvas id="drawingCanvas"></canvas>
                <i class="fa-solid fa-download" id="downloadButton"></i>
                <i class="fa-solid fa-pen-to-square" title='Độ dày nét'></i>
                <input
                    className='lineWidthSliders'
                    type="range"
                    id="lineWidthSlider"
                    min="1"
                    max="20"
                    defaultValue="8"
                />
                <i className="fa-solid fa-delete-left" title='Xóa chữ' id="clearButton"></i>
            </div>
        );
    }
}

export default DrawAlphabet;