import React, { Component } from 'react';
import MenuMember from './MenuMember';
import { connect } from 'react-redux';
import { isAuthUser } from '../react-redux/actions/authAction';
import { logOutUser } from '../react-redux/actions/logOutAction';
import { Navigate } from 'react-router-dom';
import { levelShow } from '../react-redux/actions/levelAction';
import LevelVocab from '../level/LevelVocab';
import { vocabularyShow } from '../react-redux/actions/studyAction';

class SeeVocabMember extends Component {
    componentDidMount() {
        this.props.isAuthUser();
        if (this.props.level.seeLevel === null) {
            this.props.levelShow()
        }
        if (this.props.study.vocabularyData === null) {
            this.props.vocabularyShow();
        }
    }

    logOutButton = () => {
        this.props.logOutUser();
    }

    showData = () => {
        if (this.props.level.seeLevel !== null) {
            if (this.props.study.vocabularyData !== null) {
                return this.props.level.seeLevel.map((value,key) => {
                    return (
                        <LevelVocab key={`vocab-${key}`} see = '/seeVocabMember/' content={value.level_name} levelID={value.level_id} vocabularyData = {this.props.study.vocabularyData}/>
                    )
                })
            }
        }
    }

    render() {
        const {user} = this.props.auth;
        const {isNavigateLogOut} = this.props.logOut;
        if (isNavigateLogOut) {
            return <Navigate to="/login" />
        }
        const {loadingLevel} = this.props.level;
        const {loadingVocab} = this.props.study;
        if (loadingLevel || loadingVocab) {
            return (
                <div className='loading'>
                <img
                    src="/assets/image/logo.svg"
                    className="img-fluid rounded-top icon_logo_load"
                    alt=""
                />
                <h3> Loading Web</h3>
            </div>
            )
        }
        return (
            <main>
                <MenuMember fullname={user.fullname} logout={this.logOutButton}/>
                <div className="content">
                    <div className="see_vocabulary container-fluid">
                        <div className="rows">
                            {this.showData()}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        logOut: state.logOut,
        level: state.level,
        study: state.study
    }
}
const mapDispatchToProps = {
    isAuthUser,
    logOutUser,
    levelShow,
    vocabularyShow
}
export default connect(mapStateToProps, mapDispatchToProps)(SeeVocabMember);