import React, { Component } from 'react';
import MenuHome from './MenuHome';
import { connect } from 'react-redux';
import { isAuthUser } from '../react-redux/actions/authAction';
import { logOutUser } from '../react-redux/actions/logOutAction';
import { Navigate } from 'react-router-dom';
import { levelShow } from '../react-redux/actions/levelAction';
import Level from '../level/Level';
import { grammarShow } from '../react-redux/actions/studyAction';

class SeeGrammarPrivate extends Component {
    componentDidMount() {
        this.props.isAuthUser();
        if (this.props.level.seeLevel === null) {
            this.props.levelShow()
        }
        if (this.props.study.grammarData === null) {
            this.props.grammarShow();
        }
    }

    logOutButton = () => {
        this.props.logOutUser();
    }

    showData = () => {
        if (this.props.level.seeLevel !== null) {
            if (this.props.study.grammarData !== null) {
                return this.props.level.seeLevel.map((value) => {
                    return (
                        <Level key={`grammar-${value.level_id}`} see = '/seeGrammarUser/' content={value.level_name} levelID={value.level_id} grammarData = {this.props.study.grammarData}/>
                    )
                })
            }
        }
    }
    render() {
        const {user} = this.props.auth;
        const {isNavigateLogOut} = this.props.logOut;
        if (isNavigateLogOut) {
            return <Navigate to="/login" />
        }
        const {loadingLevel} = this.props.level;
        const {loadingGrammar} = this.props.study;
        if (loadingLevel || loadingGrammar) {
            return (
                <div className='loading'>
                <img
                    src="/assets/image/logo.svg"
                    className="img-fluid rounded-top icon_logo_load"
                    alt=""
                />
                <h3> Loading Web</h3>
            </div>
            )
        }
        return (
            <main>
                <MenuHome fullname = {user.fullname} logout = {this.logOutButton}/>
                <div className="content">
                    <div className="see_grammar container-fluid">
                        <div className="rows">
                            {this.showData()}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        logOut: state.logOut,
        level: state.level,
        study: state.study
    }
}
const mapDispatchToProps = {
    isAuthUser,
    logOutUser,
    levelShow,
    grammarShow
}
export default connect(mapStateToProps, mapDispatchToProps)(SeeGrammarPrivate);