import React, { Component } from 'react';
import { connect } from 'react-redux';
import { lessonCommunicationDone } from '../react-redux/actions/reviewAction';

class LessonCommunicationMember extends Component {
    componentDidMount() {
        if (this.props.review.lessonCommunicationDoneData === null) {
            this.props.lessonCommunicationDone()
        }
    }
    
    showlesson = () => {
        if (this.props.review.lessonCommunicationDoneData !== null) {
            const lessonCommunicationDone = this.props.review.lessonCommunicationDoneData.filter(value => value.user_id === this.props.user_id && value.communication_id === this.props.communication_id);
            if (lessonCommunicationDone.length > 0) {
                return (
                    <a href={this.props.linkto} className="btn btn-primary">
                        <h2>{this.props.lesson}</h2>
                        <img
                            src="/assets/image/completed.png"
                            className="img-fluid rounded-top complete"
                            alt=""
                        />
                    </a>
                )
            } else {
                return (
                    <a href={this.props.linkto} className="btn btn-primary">
                        <h2>{this.props.lesson}</h2>
                    </a>
                )
            }
        }
    }
    componentDidUpdate() {
        const lessonDone = document.querySelectorAll('img.img-fluid.rounded-top.complete')
        const lastLessonDone = lessonDone[lessonDone.length - 1];
        if (lastLessonDone) {
            lastLessonDone.scrollIntoView({ behavior: 'smooth' });
            // Chờ cho cuộn hoàn tất
            setTimeout(() => {}, 3000);
        }
    }
    render() {
        return (
            <div className="col-10">
                <div className="d-grid gap-2 mb-3">
                    {this.showlesson()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        review: state.review
    }
}
const mapDispatchToProps =  {
    lessonCommunicationDone
}
export default connect(mapStateToProps, mapDispatchToProps)(LessonCommunicationMember);