import React, { Component } from 'react';
import MenuIndex from './MenuIndex';
import Level from '../level/Level';
import { connect } from 'react-redux';
import { levelShow } from '../react-redux/actions/levelAction';
import { grammarShow } from '../react-redux/actions/studyAction';

class SeeGrammar extends Component {
    componentDidMount() {
        if (this.props.level.seeLevel === null) {
            this.props.levelShow()
        }
        if (this.props.study.grammarData === null) {
            this.props.grammarShow();
        }
    }
    showData = () => {
        if (this.props.level.seeLevel !== null) {
            if (this.props.study.grammarData !== null) {
                return this.props.level.seeLevel.map((value) => {
                    return (
                        <Level key={`grammar-${value.level_id}`} see = '/seeGrammar/' content={value.level_name} levelID={value.level_id} grammarData = {this.props.study.grammarData}/>
                    )
                })
            }
        }
    }
    render() {
        const {loadingLevel} = this.props.level;
        const {loadingGrammar} = this.props.study;
        if (loadingLevel && loadingGrammar) {
            return (
                <div className='loading'>
                <img
                    src="/assets/image/logo.svg"
                    className="img-fluid rounded-top icon_logo_load"
                    alt=""
                />
                <h3> Loading Web</h3>
            </div>
            )
        }
        return (
            <main>
                <MenuIndex/>
                <div className="content">
                    <div className="see_grammar container-fluid">
                        <div className="rows">
                            {this.showData()}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        level: state.level,
        study: state.study
    }
}
const mapDispatchToProps = {
    levelShow,
    grammarShow
}
export default connect(mapStateToProps, mapDispatchToProps)(SeeGrammar)