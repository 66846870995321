import React, { Component } from 'react';

class InstructionNotice extends Component {
    componentDidMount() {
        const iconClose = document.querySelector('.reviewAllVocabGrammar.noVocabGrammar .instruction_notice .fa-solid.fa-xmark');
        iconClose.onclick = function () {
            const instruction_notice = document.querySelector('.reviewAllVocabGrammar.noVocabGrammar .instruction_notice');
            instruction_notice.classList.toggle('active')
        }
        this.time = setInterval(() => {
            this.autoSlide();
        }, 5000);
    
        const image_guide = document.querySelectorAll('.instruction_notice .image_guide img');
        let numberImage_guideNow = 0;
        const numberImage_guide = image_guide.length;
    
        const updateActiveImage = (index) => {
            image_guide.forEach(img => img.classList.remove('active'));
            image_guide[index].classList.add('active');
        }
    
        // Xử lý clickRight
        const clickRight = document.querySelector('i.fa-solid.fa-chevron-right');
        if (clickRight) {
            clickRight.onclick = () => {
                clearInterval(this.time);
                if (numberImage_guideNow < numberImage_guide - 1) {
                    numberImage_guideNow++;
                } else {
                    numberImage_guideNow = 0;
                }
                updateActiveImage(numberImage_guideNow);
            }
        }
    
        // Xử lý clickLeft
        const clickLeft = document.querySelector('.icon i.fa-solid.fa-chevron-left');
        if (clickLeft) {
            clickLeft.onclick = () => {
                clearInterval(this.time);
                if (numberImage_guideNow > 0) {
                    numberImage_guideNow--;
                } else {
                    numberImage_guideNow = numberImage_guide - 1;
                }
                updateActiveImage(numberImage_guideNow);
            }
        }
    }
    componentWillUnmount() {
        clearInterval(this.time);
    }
    autoSlide = () => {
        const slides = document.querySelectorAll(".image_guide img");
        var positionSlide = 0;
        var slideNow = document.querySelector(".image_guide img.active");
        for (positionSlide = 0; slideNow = slideNow.previousElementSibling; positionSlide++) { }
        //check dk slide cuoi cung
        if (positionSlide < (slides.length - 1)) {
            for (let i = 0; i < slides.length; i++) {
                slides[i].classList.remove("active");
            }
            slides[positionSlide].nextElementSibling.classList.add("active");
        } else {
            for (let i = 0; i < slides.length; i++) {
                slides[i].classList.remove("active");
            }
            slides[0].classList.add("active");
        }

    }
    render() {
        return (
            <div className="instruction_notice">
                <h3 className='tittle'>Chào mừng bạn đến với <br></br> website tự học tiếng nhật</h3>
                <i class="fa-solid fa-xmark"></i>
                <div className="image_guide">
                    <img
                        src="/assets/image/studyAlphabet.png"
                        className="img-fluid rounded-top active"
                        alt=""
                    />
                    <img
                        src="/assets/image/studyVocab.png"
                        className="img-fluid rounded-top"
                        alt=""
                    />
                    <img
                        src="/assets/image/studyGrammar.png"
                        className="img-fluid rounded-top"
                        alt=""
                    />
                    <img
                        src="/assets/image/studyCommunication.png"
                        className="img-fluid rounded-top"
                        alt=""
                    />
                    <img
                        src="/assets/image/reviewGrammar.png"
                        className="img-fluid rounded-top"
                        alt=""
                    />
                    <img
                        src="/assets/image/reviewVocab.png"
                        className="img-fluid rounded-top"
                        alt=""
                    />
                </div>
                <div className="icon">
                    <div className="left">
                        <i className="fa-solid fa-chevron-left"></i>
                    </div>
                    <div className="right">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstructionNotice;