import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class InstructionSignUpMember extends Component {
    componentDidMount() {
        const iconClose = document.querySelector('.fa-solid.fa-xmark');
        iconClose.onclick = function () {
            const instruction_notice = document.querySelector('.instruction_notice.signUpMember');
            instruction_notice.classList.toggle('active')
        }
    }
    
    render() {
        return (
            <div className="instruction_notice signUpMember">
                <h3 className='tittle'>Chào mừng bạn đến với <br></br> website tự học tiếng nhật</h3>
                <i className="fa-solid fa-xmark"></i>
                <div className='contentSignUpMember'>
                    <h4>Tham gia học thử miễn phí</h4>
                    <p style={{ color: "red" }}>(Từ vựng, ngữ pháp, bảng chữ cái, giao tiếp)</p>
                    <h4>Đăng ký thành viên
                        <Link to ='/signUpMember'
                            name=""
                            id=""
                            className="btn btn-primary signUpMember"
                            role="button"
                            >Tại đây</Link
                        >
                        </h4>  
                    <p>(Mở khóa tất cả khóa học và ôn tập)</p>               
                    {this.props.showTimeStudy} 
                </div>
            </div>
        );
    }
}
export default InstructionSignUpMember;