import React, { Component } from 'react';
import MenuIndex from './MenuIndex';
import {connect} from 'react-redux';
import { levelShow } from '../react-redux/actions/levelAction';
import LevelVocab from '../level/LevelVocab';
import { vocabularyShow } from '../react-redux/actions/studyAction';

class SeeVocabulary extends Component {
    componentDidMount() {
        if (this.props.level.seeLevel === null) {
            this.props.levelShow()
        }
        if (this.props.study.vocabularyData === null) {
            this.props.vocabularyShow();
        }
    }
    showData = () => {
        if (this.props.level.seeLevel !== null) {
            if (this.props.study.vocabularyData !== null) {
                return this.props.level.seeLevel.map((value,key) => {
                    return (
                        <LevelVocab key={`vocab-${key}`} see = '/seeVocabulary/' content={value.level_name} levelID={value.level_id} vocabularyData = {this.props.study.vocabularyData}/>
                    )
                })
            }
        }
    }
    render() {
        const {loadingLevel} = this.props.level;
        const {loadingVocab} = this.props.study;
        if (loadingLevel && loadingVocab) {
            return (
                <div className='loading'>
                <img
                    src="/assets/image/logo.svg"
                    className="img-fluid rounded-top icon_logo_load"
                    alt=""
                />
                <h3> Loading Web</h3>
            </div>
            )
        }
        return (
            <main>
                <MenuIndex/>
                <div className="content">
                    <div className="see_vocabulary container-fluid">
                        <div className="rows">
                            {this.showData()}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        level: state.level,
        study: state.study
    }
}
const mapDispatchToProps = {
    levelShow,
    vocabularyShow
}

export default connect(mapStateToProps, mapDispatchToProps)(SeeVocabulary)